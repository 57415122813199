var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "components.HostOrderOverview.title",
                expression: "'components.HostOrderOverview.title'"
              }
            ]
          }),
          _c(
            "CCardBody",
            [
              _c("CSelect", {
                attrs: {
                  label: _vm.$t("components.HostOrderOverview.label"),
                  options: _vm.orderOverviewMonthOptions,
                  value: _vm.orderOverviewMonth
                },
                on: {
                  "update:value": function($event) {
                    _vm.orderOverviewMonth = $event
                  }
                }
              }),
              _vm._l(_vm.orderOverviewData.data.lineItems, function(
                playroomLineItem
              ) {
                return _c(
                  "div",
                  {
                    key: playroomLineItem.position,
                    staticClass: "orderOverview"
                  },
                  [
                    _c("strong", [_vm._v("Playroom:")]),
                    _vm._v(" " + _vm._s(playroomLineItem.playroomName) + " "),
                    _c(
                      "CRow",
                      { staticClass: "orderOverviewHeadRow" },
                      [
                        _c("CCol", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "components.HostOrderOverview.position",
                              expression:
                                "'components.HostOrderOverview.position'"
                            }
                          ],
                          attrs: { md: "1" }
                        }),
                        _c("CCol", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "components.HostOrderOverview.date",
                              expression: "'components.HostOrderOverview.date'"
                            }
                          ],
                          attrs: { md: "2" }
                        }),
                        _c("CCol", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "components.HostOrderOverview.guestName",
                              expression:
                                "'components.HostOrderOverview.guestName'"
                            }
                          ],
                          attrs: { md: "5" }
                        }),
                        _c("CCol", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "components.HostOrderOverview.provision",
                              expression:
                                "'components.HostOrderOverview.provision'"
                            }
                          ],
                          attrs: { md: "2" }
                        }),
                        _c("CCol", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "components.HostOrderOverview.totalAmount",
                              expression:
                                "'components.HostOrderOverview.totalAmount'"
                            }
                          ],
                          attrs: { md: "2" }
                        })
                      ],
                      1
                    ),
                    _vm._l(playroomLineItem.invoicePlayroomLineItems, function(
                      lineItem
                    ) {
                      return _c(
                        "CRow",
                        { key: lineItem.position },
                        [
                          _c("CCol", { attrs: { md: "1" } }, [
                            _vm._v(_vm._s(lineItem.position))
                          ]),
                          _c("CCol", { attrs: { md: "2" } }, [
                            _vm._v(
                              _vm._s(_vm.formatIsoDateTimeTz(lineItem.date))
                            )
                          ]),
                          _c("CCol", { attrs: { md: "5" } }, [
                            _vm._v(_vm._s(lineItem.guestName))
                          ]),
                          _c("CCol", { attrs: { md: "2" } }, [
                            _vm._v(
                              _vm._s(_vm.formatCurrency(lineItem.provision))
                            )
                          ]),
                          _c("CCol", { attrs: { md: "2" } }, [
                            _vm._v(
                              _vm._s(_vm.formatCurrency(lineItem.totalAmount))
                            )
                          ])
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "6", lg: "4" } },
            [
              _c(
                "CWidgetBrand",
                {
                  staticClass: "w-100",
                  attrs: {
                    color: "secondary",
                    rightHeader: String(
                      _vm.orderData.data.futureSuccessOrderCount
                    ),
                    rightFooter: _vm.$t(
                      "pages.owner.OwnerDashboardIndex.future"
                    ),
                    leftHeader: String(_vm.orderData.data.orderCount),
                    leftFooter: _vm.$t("pages.owner.OwnerDashboardIndex.total")
                  }
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "OwnerOrderIndex" } } },
                    [
                      _c("CIcon", {
                        staticClass: "my-4",
                        attrs: { name: "cil-cart", height: "32" }
                      }),
                      _c("h2", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.owner.OwnerDashboardIndex.orders")
                            )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.orderData.loading
                ? _c("CElementCover", { attrs: { opacity: 0.8 } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "6", lg: "4" } },
            [
              _c(
                "CWidgetBrand",
                {
                  staticClass: "w-100",
                  attrs: {
                    color: "secondary",
                    rightHeader: _vm.formatCurrency(
                      _vm.amountData.data.futureSum
                    ),
                    rightFooter: _vm.$t(
                      "pages.owner.OwnerDashboardIndex.future"
                    ),
                    leftHeader: _vm.formatCurrency(_vm.amountData.data.pastSum),
                    leftFooter: _vm.$t("pages.owner.OwnerDashboardIndex.past")
                  }
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "OwnerOrderIndex" } } },
                    [
                      _c("CIcon", {
                        staticClass: "my-4",
                        attrs: { name: "cil-dollar", height: "32" }
                      }),
                      _c("h2", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.owner.OwnerDashboardIndex.income")
                            )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.amountData.loading
                ? _c("CElementCover", { attrs: { opacity: 0.8 } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "6", lg: "4" } },
            [
              _c(
                "CWidgetBrand",
                {
                  staticClass: "w-100",
                  attrs: {
                    color: "secondary",
                    rightHeader: String(_vm.customerData.data.customerCount),
                    rightFooter: _vm.$t("pages.owner.OwnerDashboardIndex.all"),
                    leftHeader: String(
                      _vm.customerData.data.successOrderCustomerCount
                    ),
                    leftFooter: _vm.$t(
                      "pages.owner.OwnerDashboardIndex.tenants"
                    )
                  }
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "OwnerOrderIndex" } } },
                    [
                      _c("CIcon", {
                        staticClass: "my-4",
                        attrs: { name: "cil-people", height: "32" }
                      }),
                      _c("h2", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.owner.OwnerDashboardIndex.guests")
                            )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.customerData.loading
                ? _c("CElementCover", { attrs: { opacity: 0.8 } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        _vm._l(_vm.playroomVisitStatistics, function(playroomVisitStatistic) {
          return _c(
            "CCol",
            { key: playroomVisitStatistic.name, attrs: { md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(
                      " Besuche pro Woche: " +
                        _vm._s(playroomVisitStatistic.name) +
                        " "
                    )
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c("CChartLine", {
                        staticClass: "mx-auto",
                        attrs: {
                          type: "line",
                          labels: _vm.playroomVisitLabel(
                            playroomVisitStatistic.weekDataList
                          ),
                          datasets: [
                            {
                              data: _vm.playroomVisitData(
                                playroomVisitStatistic.weekDataList
                              ),
                              backgroundColor: "#011f4b",
                              borderColor: "#03396c",
                              label: "Visits",
                              fill: false
                            }
                          ],
                          options: {
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    precision: 0
                                  }
                                }
                              ]
                            }
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c("HostOrderOverview")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
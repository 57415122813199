import { render, staticRenderFns } from "./HostOrderOverview.vue?vue&type=template&id=5ef93b64&scoped=true&"
import script from "./HostOrderOverview.vue?vue&type=script&lang=ts&"
export * from "./HostOrderOverview.vue?vue&type=script&lang=ts&"
import style0 from "./HostOrderOverview.vue?vue&type=style&index=0&id=5ef93b64&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef93b64",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ef93b64')) {
      api.createRecord('5ef93b64', component.options)
    } else {
      api.reload('5ef93b64', component.options)
    }
    module.hot.accept("./HostOrderOverview.vue?vue&type=template&id=5ef93b64&scoped=true&", function () {
      api.rerender('5ef93b64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/HostOrderOverview.vue"
export default component.exports